// @flow
import React, { useState, useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { SlideModal, DropzoneArea, LoadingButton } from "@fas/ui-core";
import type { PromiseResponse, Response } from "@fas/ui-framework/lib/services/request";
import {
  Divider, DialogActions, Button,
} from "@mui/material";
import SparkMD5 from "spark-md5";
import MessageApi from "../../services/messageApi";
import type {
  UseState,
  TEncodeFile,
  TEncodeFileResolve,
  TEncodeFileReject,
} from "./types/UploadFileModal.types";
import type { AdsBoxImageType } from "../../services/messageApi/message";

type Props = {
  open: boolean,
  onSave: (string) => mixed,
  onClose: () => mixed,
  onUploadError?: (Error) => mixed,
};

const encodeImage: TEncodeFile = async (file) => new Promise(
  (resolve: TEncodeFileResolve, reject: TEncodeFileReject) => {
    const reader: FileReader = new FileReader();
    reader.onload = (event) => {
      // $FlowFixMe
      resolve(event.target.result.replace(`data:${file.type};base64,`, ""));
    };
    reader.onerror = (event: ProgressEvent) => {
      reader.abort();
      reject(event);
    };
    reader.readAsDataURL(file);
  }
);

const MEGABYTE: number = 1024 * 1024;

const UploadFileModal: StatelessFunctionalComponent<Props> = ({
  open,
  onSave,
  onClose,
  onUploadError = () => {},
}) => {
  const [picture, setPicture]: UseState<File | null> = useState(null);
  const [loading, setLoading]: UseState<boolean> = useState(false);
  const [pictureId, setPictureId]: UseState<string> = useState("");

  let imageBase64: null | ArrayBuffer | string;

  useEffect(() => {
    if (!picture) {
      return;
    }

    encodeImage(picture)
      .then((data: null | ArrayBuffer | string): PromiseResponse<mixed, null | AdsBoxImageType> => {
        imageBase64 = data;
        return MessageApi.checkImage(SparkMD5.hash(data));
      })
      .then((response: Response<mixed, null | AdsBoxImageType>): Promise<void> => {
        if (response.data && response.data.shortImageUrl) {
          setPictureId(response.data.shortImageUrl);
          return Promise.reject(new Error(`This image has already been uploaded under id "${(response.data || {}).bannerId}", will use link to this one`));
        }
        return Promise.resolve();
      })
      .then((): PromiseResponse<mixed, AdsBoxImageType> => MessageApi.sendImage(imageBase64, picture.name))
      .then((response: Response<mixed, AdsBoxImageType>): void => setPictureId(response.data.shortImageUrl))
      .catch(onUploadError)
      .finally((): void => setLoading(false));
  }, [picture]);

  const handleChange: (File | null) => void = (file) => {
    if (!file) {
      return;
    }
    setLoading(true);
    setPicture(file);
  };

  const handleSave: () => mixed = () => onSave(pictureId);

  return (
    <SlideModal
      open={open}
      title="Upload"
      onClose={onClose}
      content={(
        <DropzoneArea
          initialFiles={picture ? [picture] : []}
          showAlerts={["error"]}
          alertSnackbarProps={{ anchorOrigin: { horizontal: "center", vertical: "bottom" } }}
          maxFiles={1}
          maxSize={MEGABYTE * 5}
          accept={["image/jpeg", "image/png", "image/gif", "image/jpg", "image/webp"]}
          onChange={([file = null]: File[] | null[]): void => handleChange(file)}
        />
      )}
      actions={(
        <>
          <Divider />
          <DialogActions>
            <Button onClick={onClose} data-testid="backModalButton">
              Back
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleSave}
              color="primary"
              data-testid="saveModalButton"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      )}
    />
  );
};

export default UploadFileModal;
