// @flow
/* eslint-disable no-await-in-loop */
/* eslint-disable import/max-dependencies */
import React, { useState, useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { SlideModal, DropzoneArea, LoadingButton } from "@fas/ui-core";
import { useDispatch } from "react-redux";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { Response } from "@fas/ui-framework/lib/services/request";

import {
  Divider, DialogActions, Button,
} from "@mui/material";
import SparkMD5 from "spark-md5";
import MessageApi from "../../services/messageApi";
import type {
  UseState,
  ImageParams,
  TEncodeFile,
  TEncodeFileResolve,
  Image as ImageType,
} from "./UploadResizerModal.types";
import type { AdsBoxImageType } from "../../services/messageApi/message";

type Props = {
  open: boolean,
  sizes: Array<string>,
  onSave: (Array<ImageType>) => mixed,
  onClose: () => mixed,
};

const encodeImage: TEncodeFile = (file, { width, height }: ImageParams) => new Promise(
  (resolve: TEncodeFileResolve) => {
    const reader: FileReader = new FileReader();
    reader.onload = function (readerEvent) {
      const image: Image = new Image();
      image.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL(file.type);
        resolve(dataUrl.replace(`data:${file.type};base64,`, ""));
      };
      if (readerEvent.target instanceof FileReader && typeof readerEvent.target.result === "string") {
        image.src = readerEvent.target.result;
      }
    };
    reader.readAsDataURL(file);
  }
);

const MEGABYTE: number = 1024 * 1024;

const UploadFileModal: StatelessFunctionalComponent<Props> = ({
  open,
  onSave,
  onClose,
  sizes = [],
}) => {
  const [file, setFile]: UseState<File | null> = useState(null);
  const [loading, setLoading]: UseState<boolean> = useState(false);
  const [images, setImages]: UseState<Array<ImageType>> = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!file) {
      return;
    }

    const uploadImages = async () => {
      setImages([]);
      for (const size of sizes) {
        const [width, height]: string[] = size.split("x");
        try {
          const image: string = await encodeImage(file, { width: Number(width), height: Number(height) });
          const { data }: Response<mixed, null | AdsBoxImageType> = await MessageApi.checkImage(
            SparkMD5.hash(image)
          );
          if (data) {
            setImages((prev) => [...prev, { sizes: size, src: data?.shortImageUrl || "" }]);
            dispatch(addNotification({
              message: `This image has already been uploaded under id "${data?.bannerId || ""}", will use link to this one`,
              severity: "warning",
            }));
          }
          else {
            const response: Response<mixed, AdsBoxImageType> = await MessageApi.sendImage(
              image, file.name
            );
            setImages((prev) => [
              ...prev, { sizes: size, src: response.data.shortImageUrl },
            ]);
          }
        }
        catch (error) {
          dispatch(addNotification({
            message: error.message,
            severity: "error",
          }));
        }
      }
      setLoading(false);
    };

    uploadImages();
  }, [file]);

  const handleChange: (File | null) => void = (newFile) => {
    if (!newFile) {
      return;
    }

    setLoading(true);
    setFile(newFile);
  };

  const handleClose: () => mixed = () => {
    onClose();
    setImages([]);
    setFile(null);
  };

  const handleSave: () => mixed = () => {
    onSave(images);
    handleClose();
  };

  return (
    <SlideModal
      open={open}
      title="Upload"
      onClose={handleClose}
      content={(
        <DropzoneArea
          initialFiles={file ? [file] : []}
          filesLimit={1}
          maxFiles={MEGABYTE * 5}
          accept={["image/png"]}
          onChange={([newFile = null]: File[] | null[]): void => handleChange(newFile)}
        />
      )}
      actions={(
        <>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose} data-testid="backModalButton">
              Back
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleSave}
              color="primary"
              data-testid="saveModalButton"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </>
      )}
    />
  );
};

export default UploadFileModal;
