// @flow
import React, { type Node } from "react";
import { Box, FormControl } from "@mui/material";
import { Close } from "@mui/icons-material";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { Dropdown } from "@fas/ui-core/lib/helpers/types";
import { Multiselect } from "@fas/ui-core";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

export type Props = {|
  name: string,
  label: string,
  options: $ReadOnlyArray<Option | Dropdown>,
  error: string,
  value: *,
  onChange: (*) => *,
  disabled?: boolean,
  loading?: boolean,
  enableAddAllBtn?: boolean,
  enableAddMatchesBtn?: boolean,
  clearIcon?: Node,
  InputProps?: *,
|}

function valueToOptions(value?: string[], options: $ReadOnlyArray<Option | Dropdown>): Option[] {
  return (value || []).map((v: string): Option => ({
    ...options.find(({ value: optionValue }: Option | Dropdown): boolean => v === optionValue),
  }) || { label: v, value: v });
}

function optionsToValue(options: Option[]): string[] {
  return options.map(({ value: v }: Option): string => String(v));
}

// todo: move to ui-core
function MultiSelectForm({
  name,
  label,
  options,
  value,
  error,
  onChange,
  InputProps,
  ...props
}: Props): Node {
  return (
    <FormControl fullWidth>
      <Box pt={1} pb={0.5}>
        <Multiselect
          {...props}
          InputProps={InputProps}
          label={label}
          type="simpleMultiple"
          options={((options: any): Option[])}
          selectedOptions={valueToOptions(value, options)}
          onChange={(selected: Option[]) => {
            onChange(optionsToValue(selected));
          }}
          dataTestId={name}
          error={{
            isError: Boolean(error),
            helperText: error,
          }}
        />
      </Box>
    </FormControl>
  );
}

MultiSelectForm.defaultProps = {
  onChange: () => {},
  error: "",
  value: [],
  clearIcon: <Close fontSize="small" />,
  enableAddAllBtn: false,
  InputProps: {},
};

export default withFormField(MultiSelectForm);
