// @flow
import type { OutputSelector } from "reselect";
import { createSelector } from "reselect";
import { List, Map } from "immutable";
import createCachedSelector from "re-reselect";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import getOptionLabel from "@fas/ui-core/lib/SearchComponents/helpers/getOptionLabel";
import type { Dropdown } from "@fas/ui-core/lib/helpers/types";
import type { StoreWithDictionaries } from "./types";
import type { State as DictionariesState, DropDownObjItemType, DropDownObjType } from "../../reducers/dictionaries";
import type { DropDownType } from "../../reducers/dictionaries/reducer";

export const getSubratesDictionariesDropdownLists
  : OutputSelector<StoreWithDictionaries, *, DropDownObjType> = createSelector(
    (state: StoreWithDictionaries): DictionariesState => state.dictionaries,
    (reducerState: DictionariesState): DropDownObjType => (reducerState.get("dropdownLists").toJS(): any)
  );

export const getCpaOffersDropdownLists
  : OutputSelector<StoreWithDictionaries, *, { [string]: Array<Dropdown> }> = createSelector(
    (state: StoreWithDictionaries): DropDownType => state.dictionaries.get("dropdownLists"),
    (value: DropDownType): { [string]: Array<Dropdown> } => (value.toJS(): any)
  );

const defaultList = List();

export const getDropdownList
  : OutputSelector<StoreWithDictionaries, string, Array<DropDownObjItemType>> = createCachedSelector(
    (state: StoreWithDictionaries, key: string): List<{
      label: string,
      value: string,
      // $FlowFixMe
    }> => state.dictionaries.get("dropdownLists", Map()).get(key, defaultList),
    (value: List<{
      label: string,
      value: string,
    }>): Array<DropDownObjItemType> => (value.toJS(): any)
  )((state, key) => key);

export const getOptionList
  : OutputSelector<StoreWithDictionaries, string, Array<Option>> = createCachedSelector(
    getDropdownList,
    (
      list: DropDownObjItemType[]
    ): Array<Option> => list.map((option: DropDownObjItemType) => ({
      label: getOptionLabel(option),
      value: option.value,
    }))
  )((state: StoreWithDictionaries, key: string) => key);
